<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
  </div>
  <contract-stats />
</template>

<script>
import {defineComponent, computed, onMounted, onUnmounted} from "vue";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import ContractStats from "@/components/stats/ContractStats.vue";
import Search from "@/components/Search.vue";
export default defineComponent({
  name: "ContractDetails",
  components: {
    ContractStats,
    Search,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const txnDetails = computed(
      () => store.getters["transactions/getSelectedTxn"]
    );

    const contractDetails = computed(
      () => store.getters["contracts/getSelectedContract"]
    );

    store.dispatch("contracts/fetchContractById", route.params.contract_id).then(res => {
      console.log(res);
      if (res.data?.create_transaction?.hash) {
        store.dispatch("transactions/fetchTxnByHash", res.data.create_transaction.hash);
      }
    });

    const loading = computed(
      () => store.getters["contracts/getLoadingStatus"]
    );

    const error = computed(() => store.getters["operations/getError"]);

    const scroll = () => {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset + 1 >=
          document.body.scrollHeight;

        if (bottomOfWindow) {
          store.dispatch("transactions/fetchMoreOpsForTxn");
        }
      };
    };

    onMounted(scroll);
    onUnmounted(() => {
      window.onscroll = null;
    });

    const checkTxnValue = (txnHash, value) => {
      if (txnHash in txnValue.value.data) {
        return txnValue.value.data[txnHash][value].toLocaleString();
      } else if (!txnValue.value.loading && !txnValue.value.error) {
        return 0;
      } else {
        return "-";
      }
    };

    const txnValue = computed(() => store.getters["analytics/getTxnValue"]);
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);

    return {
      txnDetails,
      contractDetails,
      loading,
      error,
      accountLabels,
      checkTxnValue,
      txnValue,
      formatDate,
      formatDateElapsedTime
    };
  },
});
</script>
