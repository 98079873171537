<template>
  <loading v-if="loading" />
  <error v-else-if="error" />
  <template v-else>
    <div class="card mb-2">
      <div class="card-body">
        <ul class="list-group">
          <li v-if="contractDetails.contract_id" class="row d-flex mx-1 border-0 py-1">
            <div class="col-xs-12 col-lg-3 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-clock me-3"></i
              >{{ $t("transaction_stats.contract_id") }}
            </div>
            <div class="col-xs-12 col-lg-9 py-2 px-3">
              <div class="form-floating">
                <span class="align-self-center small font-monospace">{{contractDetails.contract_id}}</span><router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(contractDetails?.contract_id ?? '-')"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
              </div>
            </div>
          </li>
          <li v-if="txnDetails?.contract_id" class="row d-flex mx-1 border-0 py-1">
            <div class="col-xs-12 col-lg-3 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-clock me-3"></i
              >{{ $t("transaction_stats.contract_address") }}
            </div>
            <div class="col-xs-12 col-lg-9 py-2 px-3">
              <div class="form-floating">
                <span class="align-self-center small font-monospace">{{contractAddress(txnDetails.contract_id)}}</span><router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(contractAddress(txnDetails?.contract_id) ?? '-')"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
              </div>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-hash me-3"></i
              >{{ $t("transaction_stats.transaction_hash") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{txnDetails?.hash ?? "-"}}</span>
              <router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails?.hash ?? '-')">
                  {{ $t("transaction_stats.copy") }}
                </router-link>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-hash me-3"></i
              >{{ $t("transaction_stats.ledger_hash") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{
                (txnDetails?.ledger?.hash || contractDetails.ledger) ?? "-"
              }}</span
              ><router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails?.ledger?.hash ?? '-')"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-circle me-3"></i
              >{{ $t("transaction_stats.status") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span
                v-if="txnDetails.successful"
                class="
                  badge
                  bg-success bg-opacity-25
                  text-success
                  border
                  align-self-center
                "
                >{{ $t("transaction_stats.success") }}
                <i class="bi bi-check-circle-fill ms-1"></i
              ></span>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-card-list me-3"></i
              >{{ $t("transaction_stats.ledger") }}
            </div>
            <div class="w-75 py-2 px-3">
              <router-link
                v-if="txnDetails?.ledger?.sequence"
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
                :to="{
                  name: 'LedgerDetailsRoute',
                  params: { ledger_sequence: txnDetails?.ledger?.sequence },
                }"
                >{{ txnDetails?.ledger?.sequence }}</router-link
              >
              <div v-else>-</div>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-person-square me-3"></i
              >{{ $t("transaction_stats.source_account") }}
            </div>
            <div class="w-75 py-2 px-3">
              <router-link
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
                :to="{
                  name: 'AccountDetailsRoute',
                  params: {
                    acc_id: txnDetails.asset_issuer || contractDetails.asset_issuer,
                  },
                }"
                >{{ txnDetails.source_account || contractDetails.asset_issuer}}</router-link
              ><router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails.source_account)"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-tag-fill me-3"></i
              >{{ $t("transaction_stats.label") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span
                class="
                  text-decoration-none
                  font-monospace
                  fw-bold
                  text-dark
                  small
                "
              >
                {{
                  accountLabels.data[txnDetails.source_account]?.label?.name ??
                  "-"
                }}
              </span>
              <i
                v-tooltip
                class="bi ms-5"
                :class="labelData(txnDetails.source_account)?.icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  labelData(txnDetails.source_account)?.tooltip
                "
              ></i>
              <router-link
                :to="{
                  name: 'DirectoryUpdateRoute',
                  query: {
                    account: txnDetails.source_account || contractDetails.asset_issuer,
                  },
                }"
                class="float-end text-decoration-none"
                >{{ $t("transaction_stats.update") }}
              </router-link>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-hash me-3"></i
              >{{ $t("transaction_stats.source_account_sequence") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
              >
                {{ txnDetails.source_account_sequence }}</span
              >
              <router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails.source_account_sequence)"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-cash me-3"></i
              >{{ $t("transaction_stats.fee_account") }}
            </div>
            <div class="w-75 py-2 px-3">
              <router-link
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
                :to="{
                  name: 'AccountDetailsRoute',
                  params: {
                    acc_id: contractDetails.asset_issuer || txnDetails.fee_account,
                  },
                }"
                >{{ txnDetails.fee_account || contractDetails.asset_issuer }}</router-link
              >
              <span
                v-if="txnDetails.fee_account === txnDetails.source_account"
                v-tooltip
                class="badge rounded-pill bg-secondary ms-2"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                :data-bs-original-title="
                  $t('transaction_stats.fee_account_matches_source')
                "
                >{{ $t("transaction_stats.matching") }}</span
              >
              <router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails.fee_account)"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-clock me-3"></i
              >{{ $t("transaction_stats.created_at") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{
                formatDate(txnDetails.created_at) +
                " · " +
                formatDateElapsedTime(txnDetails.created_at)
              }}</span>
            </div>
          </li>
          <li v-if="contractDetails.asset_code" class="list-group-item d-flex p-0 border-0 py-1">
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-clock me-3"></i
                >{{ $t("transaction_stats.asset_code") }}
              </div>
              <div class="w-75 py-2 px-3">
                <div class="form-floating">
                  <span class="align-self-center small font-monospace">{{contractDetails.asset_code}}</span>
                </div>
              </div>
          </li>
          <li v-if="contractDetails.transactions_count" class="list-group-item d-flex p-0 border-0 py-1">
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-clock me-3"></i
                >{{ $t("effect_types.total_invocations") }}
              </div>
              <div class="w-75 py-2 px-3">
                <div class="form-floating">
                  <span class="align-self-center small font-monospace">{{contractDetails.transactions_count}}</span>
                </div>
              </div>
          </li>
          <li v-if="contractDetails?.contract_type !== null" class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary">
              <i class="bi bi-clock me-3"></i
              >{{ $t("transaction_stats.contract_type") }}
            </div>
            <div class="w-75 py-2 px-3">
              <div class="form-floating">
                <span class="align-self-center small font-monospace">{{contractTypes[contractDetails.contract_type]}}</span>
              </div>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0 py-1">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-clock me-3"></i
              >
                {{ $t("transaction_stats.interface_version") }}
            </div>
            <div class="w-75 py-2 px-3">
              <div class="form-floating">
                <span class="small font-monospace">
                    {{contractDetails?.contractDecoded?.env?.interface_version}} 
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <contract-and-transaction-details-tabs :contract-page="true"/>
    <div class="tab-content">
      <div id="nav-operations" class="tab-pane fade show active" role="tabpanel" aria-labelledby="nav-operations-tab">
        <template v-for="(op, idx) in txnDetails.operations" :key="idx">
          <operation-info :operation-details="op" :idx="idx" />
        </template>
        <loading v-if="loading" />
        <error v-else-if="error" />
      </div>
      <div id="nav-transactions" class="tab-pane fade show" role="tabpanel" aria-labelledby="nav-transactions-tab">
          <div v-if="contractDetails?.transactions?.length" class="list-group">
            <template v-for="(transaction, idx) in contractDetails.transactions" :key="idx">
              <div class="list-group-item">
                <div class="d-flex align-items-center">
                  <router-link
                    v-tooltip
                    class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
                    style="height: 36px; width: 36px"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('txn_summary.transaction')"
                    :to="{
                      name: 'TxnDetailsRoute',
                      params: {
                        txn_hash: transaction.hash,
                      },
                    }"
                  >
                    <i
                      class="bi bi-list-ul fs-5 align-self-center mx-auto text-primary"
                    ></i>
                  </router-link>
                  <div class="flex-shrink-0 ms-3">
                    <router-link
                      v-tooltip
                      v-middle-ellipsis="{ size: 6, text: transaction.source_account }"
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: contractDetails.asset_issuer || transaction.source_account,
                        },
                      }"
                      class="fw-bold text-decoration-none text-body font-monospace small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('txn_summary.source_account')"
                    />

                    <p class="small mb-0 font-monospace">
                      <router-link
                        v-tooltip
                        v-middle-ellipsis="{ size: 8, text: transaction.hash }"
                        :to="{
                          name: 'TxnDetailsRoute',
                          params: {
                            txn_hash: transaction.hash,
                          },
                        }"
                        class="text-muted text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="$t('txn_summary.txn_hash')"
                      />
                    </p>
                  </div>
                  <div class="flex-grow-1">
                    <div class="row ms-2">
                      <div class="col-xxl-2 col-4 d-flex">
                        <span
                          v-if="!accountLabels.data[transaction.source_account || contractDetails.asset_issuer]"
                          class="badge bg-primary bg-opacity-25 align-self-center"
                        >
                          <router-link
                            v-tooltip
                            :to="{
                              name: 'DirectoryUpdateRoute',
                              query: {
                                account: transaction.source_account || contractDetails.asset_issuer,
                              },
                            }"
                            class="
                              text-primary text-decoration-none
                              d-none d-xxl-inline-flex
                            "
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :data-bs-original-title="$t('txn_summary.add_label')"
                            ><i class="bi bi-plus-circle"></i
                          ></router-link>
                        </span>
                        <span
                          v-else-if="
                            ['Scam', 'Hack'].includes(
                              accountLabels.data[transaction.source_account].label.name
                            )
                          "
                          class="badge bg-danger bg-opacity-25 align-self-center"
                        >
                          <router-link
                            v-tooltip
                            :to="{
                              name: 'AccountDetailsRoute',
                              params: {
                                acc_id: contractDetails.asset_issuer,
                              },
                            }"
                            class="
                              text-danger text-decoration-none
                              d-none d-xxl-inline-flex
                            "
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            :data-bs-original-title="
                              $t('txn_summary.scam_tooltip', {
                                type: accountLabels.data[
                                  transaction.source_account || contractDetails.asset_issuer
                                ].label.name.toUpperCase(),
                              })
                            "
                            >{{
                              accountLabels.data[
                                transaction.source_account || contractDetails.asset_issuer
                              ].label.name.toUpperCase()
                            }}</router-link
                          >
                        </span>
                        <span v-else-if="accountLabels.data[transaction.source_account || contractDetails.asset_issuer].label.verified"
                          class="badge bg-primary bg-opacity-25 align-self-center">
                          <router-link
                            v-tooltip
                            :to="{
                              name: 'AccountDetailsRoute',
                              params: {
                                acc_id: transaction.source_account || contractDetails.asset_issuer,
                              },
                            }"
                            class="
                              text-primary text-decoration-none
                              d-none d-xxl-inline-flex
                            "
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            :data-bs-original-title="$t('txn_summary.label')"
                            >{{
                              accountLabels.data[transaction.source_account || contractDetails.asset_issuer].label.name + "&nbsp;"
                            }}</router-link
                          ><i
                            v-tooltip
                            class="bi bi-patch-check-fill text-primary"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            :data-bs-original-title="$t('txn_summary.verified_account')"
                          >
                          </i>
                        </span>

                        <span
                          v-else-if="
                            accountLabels.data[transaction.source_account || contractDetails.asset_issuer] &&
                            !accountLabels.data[transaction.source_account || contractDetails.asset_issuer].label.verified
                          "
                          class="badge bg-white border align-self-center"
                        >
                          <router-link
                            v-tooltip
                            :to="{
                              name: 'AccountDetailsRoute',
                              params: {
                                acc_id: transaction.source_account || contractDetails.asset_issuer,
                              },
                            }"
                            class="
                              text-dark text-decoration-none
                              d-none d-xxl-inline-flex
                            "
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            :data-bs-original-title="$t('txn_summary.label')"
                            >{{
                              accountLabels.data[transaction.source_account].label.name + "&nbsp;"
                            }}</router-link
                          >
                        </span>
                      </div>
                      <div class="col-xxl-4 d-xxl-block">
                        <span class="d-block small text-muted">
                            <span>{{ $t("operation_types.invoke_host_function") }}</span>
                        </span>
                        <small v-tooltip 
                            class="font-monospace"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            :data-bs-original-title="$t('operation_stats.parameters')"
                        >
                            {{transaction.host_functions?.substring(0,25)}}
                          </small>
                      </div>
                      <div class="col-xxl-2 d-none d-xxl-block">
                        <span class="d-block small text-muted">
                          {{$t('txn_details.ledger')}}
                        </span>
                        <small class="fw-bold font-monospace">
                          {{transaction.ledger || contractDetails.ledger}}
                        </small>
                      </div>
                      <div class="col-xxl-2 col-8 text-end">
                        <span class="d-block small text-muted">
                          <span>{{ $t("txn_summary.fee") }}</span>
                        </span>
                        <small
                          v-tooltip
                          class="fw-bold font-monospace"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                          :data-bs-original-title="'$ ' + checkTxnValue(transaction.hash, 'usd_fee')">
                            {{$t("txn_summary.xlm") + (0.0000001 * transaction.fee_charged).toFixed(5)}}
                        </small>
                      </div>

                      <div class="col-xxl-2 col-8 text-end">
                        <b class="d-block small text-muted">
                          <span
                            v-tooltip
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            :data-bs-original-title="formatDate(transaction.created_at)">
                          {{ formatDateElapsedTime(transaction.created_at) }}
                        </span>
                      </b>
                      <small
                        v-tooltip
                        class="fw-bold font-monospace"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="'$ ' + checkTxnValue(transaction.hash, 'usd_value')">
                          {{
                            $t("txn_summary.xlm") + checkTxnValue(transaction.hash, "xlm_value")
                          }}
                      </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-else>
           <no-content />
          </div>
        </div>
        <transaction-meta 
          :state-changes="txnDetails.stateChanges" 
          :byte-code="contractDetails?.wat" 
          :parsed-wasm="contractDetails?.contractDecoded?.env?.specs" 
          :show-events="showEvents"
          :wasm-code="contractDetails?.wasm"
          :txn-details="txnDetails"
          :contract-details="contractDetails"
        />
        <div id="nav-transaction_xdrs" class="tab-pane fade show" role="tabpanel" aria-labelledby="nav-transaction_xdrs-tab">
            <div class="alert alert-secondary">
                Decode transaction XDR's on 
                <a target="_blank" href="https://laboratory.stellar.org/#xdr-viewer?type=TransactionEnvelope&network=test">
                    Stellar Laboratory
                </a>
            </div>
            <div class="card">
                <div class="card-header">Envelope XDR</div>
                <div class="card-body">
                    <code> {{txnDetails.envelope_xdr}} </code>
                    <a class="float-end text-decoration-none cp"
                        @click="copy(txnDetails.envelope_xdr)"
                    >
                        {{ $t("transaction_stats.copy") }}
                    </a>
                </div>
            </div>
            <div class="card">
                <div class="card-header">Result XDR</div>
                <div class="card-body">
                    <code> {{txnDetails.result_xdr}} </code>
                    <a class="float-end text-decoration-none cp"
                        @click="copy(txnDetails.result_xdr)"
                    >
                        {{ $t("transaction_stats.copy") }}
                    </a>
                </div>
            </div>
            <div class="card">
                <div class="card-header">Result Meta XDR</div>
                <div class="card-body">
                    <code> {{txnDetails.result_meta_xdr}} </code>
                    <a class="float-end text-decoration-none cp"
                        @click="copy(txnDetails.result_meta_xdr)"
                    >
                        {{ $t("transaction_stats.copy") }}
                    </a>
                </div>
            </div>
            <div class="card">
                <div class="card-header">Result Meta XDR</div>
                <div class="card-body">
                    <code> {{txnDetails.fee_meta_xdr}} </code>
                    <a class="float-end text-decoration-none cp"
                        @click="copy(txnDetails.fee_meta_xdr)"
                    >
                        {{ $t("transaction_stats.copy") }}
                    </a>
                </div>
            </div>
        </div>
      </div>
  </template>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { formatDate, formatDateElapsedTime } from "@/utils/date";
import Error from "@/components/Error.vue";
import TransactionMeta from "@/components/TransactionMeta.vue";
import ContractAndTransactionDetailsTabs from "@/components/stats/ContractAndTransactionDetailsTabs.vue";
import OperationInfo from "@/components/operations/OperationInfo";
import NoContent from "@/components/NoContent.vue";
import Loading from "@/components/Loading.vue";
import { useI18n } from "vue-i18n";
import * as StellarSdk from 'stellar-sdk';

import { NetworksEnum } from "@/store/modules/settings/state";

export default defineComponent({
  name: "ContractStats",
  components: {
    Error,
    OperationInfo,
    Loading,
    NoContent,
    TransactionMeta,
    ContractAndTransactionDetailsTabs
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const { xdr, StrKey } = StellarSdk;

    const contractTypes = ref({
      1: "Contract from asset",
      0: "Contract from address",
    });

    const txnDetails = computed(
      () => store.getters["transactions/getSelectedTxn"]
    );

    const contractDetails = computed(
      () => store.getters["contracts/getSelectedContract"]
    );

    const contractAddress = (contractId) => {
        let hash = StrKey.encodeContract(
          Buffer.from(contractId, 'hex')
        )
        return hash;
    };

    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };

    const loading = computed(
      () => store.getters["contracts/getLoadingStatus"]
    );
    const error = computed(() => store.getters["transactions/getError"]);

    const txnValue = computed(() => store.getters["analytics/getTxnValue"]);
    const checkTxnValue = (txnHash, value) => {
      if (!txnValue.value.loading && !txnValue.value.error) {
        if (txnHash in txnValue.value.data) {
          return txnValue.value.data[txnHash][value].toLocaleString();
        } else {
          return 0;
        }
      } else {
        return "-";
      }
    };
    const labelData = (account) => {
      if (accountLabels.value.data[account]?.label) {
        if (
          ["Scam", "Hack"].includes(
            accountLabels.value.data[account]?.label?.name
          )
        ) {
          return {
            icon: "bi-exclamation-triangle-fill text-danger",
            tooltip: t("transaction_stats.scam_tooltip", {
              type: accountLabels.value.data[
                account
              ]?.label?.name.toUpperCase(),
            }),
          };
        }

        if (accountLabels.value.data[account]?.label.verified) {
          return {
            icon: "bi-patch-check-fill text-primary",
            tooltip: t("account_summary.verified_account"),
          };
        } else {
          return {
            icon: "bi-person-circle text-dark",
            tooltip: t("account_summary.unverified_user_defined_label"),
          };
        }
      }
    };
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    const networkType = computed(() => store.getters["settings/getNetworkType"]);


    const showEvents = computed(() => {
      let sorobanEvents = txnDetails.value.sorobanEvents;
      let events = {
        events: {},
        diagnosticEvents: []
      };
      if (sorobanEvents?.length) {
          events.data = event.data;
      }
      let diagnostic = false;
      if (sorobanEvents && diagnostic) {
        events.diagnosticEvents = sorobanEvents.diagnosticEvents();
      }
      return events;
    });

    return {
      loading,
      error,
      txnDetails,
      formatDate,
      formatDateElapsedTime,
      copy,
      txnValue,
      checkTxnValue,
      accountLabels,
      labelData,
      xdr,
      contractTypes,
      contractDetails,
      networkType,
      NetworksEnum,
      showEvents,
      contractAddress
    };
  },
});
</script>
